.logo {
  display: flex;
  align-items: center;
  text-align: center;
  
  .logo-app {
    @include verticalCenter;
    margin: 10px auto;
    width: 525px;
    height: 60px;
    border-radius: 20px;
    padding: 0px 0;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.307);
    background: url(../../assets/img/main2.jpg) center/cover;
    font-size: 1.5rem;

    img {
      height: 95%;
      width: height;
      position: relative;
    }
    div {
      height: 95%;
      width: 80%
    }
    h3 {
      margin-left: 10px;
      margin-top: 10px;
      color: $color-1;
      text-align-last: center;
    }
    h4 {
      margin-left: 10px;
      color: $color-1;
      font-size: 0.75rem;
      text-align-last: center;
    }
  }

  .logo-metamask {
    max-width: 200px;
    margin-right: 20px;

    button {
      margin: 5px;
      // margin-top: 20px;
      background: none;
      border: 2px solid $color-5;
      padding: 4px 10px;
      border-radius: 10px;
      cursor: pointer;
      transition: 0.2s;

      &:hover {
        background: $color-1;
        color:$white;
      }
    }
    h3 {
      margin-left: 10px;
      margin-top: 0px;
      color: $color-5;
      text-align-last: center;
      font-size: 1rem;
    }
    h4 {
      margin-left: 10px;
      margin-top: 0px;
      color: $color-5;
      text-align-last: center;
      font-size: 0.75rem;
    }
    p{
      color:$color-1;
      font-size: 0.75rem;
    }
  }
}
