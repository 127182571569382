.makeofferviews {
  text-align: center;

  
  .table-gr-container {
    display: flex;

    .table-container {
      justify-content: space-around;
      align-items: center;
      background: $white ;
      border-radius: 20px;
      padding: 5px;
      color: $white;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.452);
      width: 48%;
      margin-left:auto;
      margin-right:auto;
      text-align: center;

      table {
        transform: translateY(1px);
        width: 100%;
        color: black;

        tr {
          color: black;
          align-items: center;
          
        }
      }
    }

    .table-container-items {
      justify-content: space-around;
      // align-items: center;
      background: $white ;
      border-radius: 20px;
      padding: 10px;
      color: $white;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.452);
      width: 46%;
      margin-left:auto;
      margin-right:auto;
      text-align: center;

      table {
        transform: translateY(1px);
        width: 100%;
        color: black;

        tr {
          color: black;
          align-items: center;
          
        }
      }
    }

    .table-container-buy {
      // justify-content: space-evenly;
      align-items: center;
      background: $white ;
      border-radius: 20px;
      // margin: 30px;
      padding: 5px;
      color: $white;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.452);
      // width: 150px;
      margin:10px;
      margin-bottom:20px;
      text-align: center;

      table {
        transform: translateY(1px);
        width: 100%;
        color: black;

        tr {
          color: black;
          align-items: center;
          
        }
      }
    }

    .table-container-help {
      justify-content: space-around;
      align-items: center;
      background: $white ;
      border-radius: 20px;
      color:$color-5;
      padding: 10px;
      // margin: 20px;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.452);
      width: 46%;
      margin-left:auto;
      margin-right:auto;
      text-align: center;

      table {
        // transform: translateY(1px);
        width: 90%;
        color: black;

        tr {
          color: black;
          align-items: center;
          
        }
      }

      
      h1{
        color: $color-5;
        margin-top: 5px;
        margin-left: 5px;
        margin-bottom: 10px;
        font-size: 1.3rem;
        // text-align: left;
      }

      h2{
        color: $color-5;
        margin-top: 5px;
        margin-left: 10px;
        margin-bottom: 0px;
        font-size: 1.1rem;
        // text-align: left;
      }

      h2#yield{
        color: $color-4;
      }

      h3{
        color: $color-5;
        margin-top: 5px;
        margin-left: 10px;
        margin-bottom: 0px;
        font-size: 0.9rem;
        text-align: left;
      }
    }

    h1{
      color: $color-5;
      margin-top: 5px;
      margin-left: 5px;
      margin-bottom: 10px;
      font-size: 1.3rem;
      // text-align: left;
    }

    h1#running{
      margin-top: 15px;
      color: $color-5;
    }

    h1#message{
      margin-top: 15px;
      color: $color-7;
    }

    h1#error{
      margin-top: 15px;
      color: $color-4;
    }

    h1#warning{
      margin-top: 15px;
      color: $color-6;
    }

    h2{
      color: $color-5;
      margin-top: 5px;
      margin-left: 10px;
      margin-bottom: 0px;
      font-size: 1.1rem;
      // text-align: left;
    }

    h2#yield{
      color: $color-4;
    }

    h3{
      color: $color-5;
      margin-top: 5px;
      margin-left: 10px;
      margin-bottom: 0px;
      font-size: 0.9rem;
      text-align: left;
    }

    h3#red{
      color: $color-4;
      margin-top: 5px;
      margin-left: 10px;
      margin-bottom: 0px;
      font-size: 0.9rem;
      text-align: left;
    }

    h3#center{
      color: $color-5;
      margin-top: 5px;
      margin-left: 10px;
      margin-bottom: 0px;
      font-size: 0.9rem;
      text-align: center;
    }

    

    h3#center-red{
      color: $color-4;
      margin-top: 5px;
      margin-left: 10px;
      margin-bottom: 0px;
      font-size: 0.9rem;
      text-align: center;
    }

    
    label {
      margin-left: 5px;
      cursor: pointer;
    }
  }

  

  .radio-container {
    margin-bottom: 20px;
    background: $color-3;
    border-radius: 15px;
    padding: 8px;
    color: $white;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.452);
    width: 98%;
    margin: auto;
    margin-bottom: 10px;

    ul {
      justify-content: space-around;
      align-items: center;
      margin: 0px;
      display: flex;
      text-align: left;

      div {
        padding: 0px 4px;

        li {

          input {
            transform: translateY(1px);
          }
          
          label {
            margin-left: 5px;
            cursor: pointer;
          }
        }

        li#spinner {
          display: flex;
        }
        li#radio {
          display: flex;
          margin: 3px;
          align-items: center;
          align-self: center;
        }
        li#text{
          display: flex;
        }
        li#checkbox{
          display: flex;
          min-width: 280px;
          align-self: center;
          margin: 5px;
        }
        li#img-swap{
          margin-top: 20px;
          cursor: pointer;
          text-align: center;
        }
        li#img-swapV{
          margin-top: 10px;
          margin-bottom: 5px;
          cursor: pointer;
          text-align: center;
        }
        li#img-send{
          cursor: pointer;
        }
      }
    }
  }

  // input[type=search]::-webkit-search-cancel-button {
  //   -webkit-appearance: searchfield-cancel-button;
  // }

  input[type="search"] {
    padding: 2px;
    border-radius: 40px;
    font-size: 0.9rem;
    border: 3px solid $color-1;
    outline: none;
    transition: 0.4s;
    text-align: center;
    // width: 80px;
    margin-bottom: 0px;

    &:focus {
      background: $color-1;
      border-radius: 4px;
      color: $white;
    }
  }

  input[type="number"] {
    padding: 2px;
    border-radius: 40px;
    font-size: 0.9rem;
    border: 3px solid $color-1;
    outline: none;
    transition: 0.4s;
    text-align: center;
    width: 100px;
    margin-bottom: 0px;

    &:focus {
      background: $color-1;
      border-radius: 4px;
      color: $white;
    }
  }

  input[type="text-adr"] {
    padding: 2px;
    margin-left: 5px;
    border-radius: 40px;
    font-size: 0.75rem;
    border: 3px solid $color-1;
    outline: none;
    transition: 0.4s;
    text-align: center;
    width: 100%;

    &:focus {
      background: $color-1;
      border-radius: 4px;
      color: $white;
    }
  }

  button {
    margin: 5px 5px;
    background: none;
    border: 2px solid $color-5;
    padding: 4px 10px;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.2s;
    color: $color-5;

    &:hover {
      background: $color-5;
    }
  }
}
