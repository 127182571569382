@font-face {
  font-family: "font-1";
  src: url(../assets/fonts/JosefinSans-Medium.ttf);
}

$font-1: "font-1", sans-serif;

$color-1: #5ad3f5;
$color-2: salmon;
$color-3: rgb(55, 55, 55);
$color-4: #db0e0e;
$color-5: #2e56d8;
$color-6: #ec850f;
$color-7: #24af1f;
$white: rgb(244, 244, 244);

@mixin verticalCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background: $white;
  font-family: $font-1;
  // @include verticalCenter;
}
li {
  list-style-type: none;
}
